.circle{
    height: 15px;
    width: 15px !important;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
}
.keyText{
    font-size: 12px;
    margin-left: 5px;
}